<template>
  <list-container-widget
    id="success-stories"
    :title="widgetName"
    :app-id="75"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="success-stories" />
      <CreateNewButton item-type="success-stories" />
    </template>
    <!-- Loading -->
    <b-card-body>
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0">
        <div ref="container" class="x-scroll-container">
          <!-- Items Loop -->
          <b-card
            v-for="item in items"
            v-show="canView(item)"
            :key="item.key"
            class="x-scroll-element mx-1 mb-3 p-0 card-standard card-container"
          >
            <b-link :to="{ name: 'successstory', params: { id: item.key } }">
              <div class="item-img text-center">
                <b-img
                  :alt="`${item.name}`"
                  fluid
                  :src="getImageSrc(item.banner) || Placeholder"
                  class="card-image-medium"
                />
              </div>
             <!--  <b-badge
                v-if="item.createdAt"
                class="my-1"
                variant="light-primary"
              >
                {{ timestampToTime(item.createdAt) }}
              </b-badge> -->
              <h4 class="mt-1 mb-75 min-height-3">
                {{ item.name }}
              </h4>
              <span
                v-if="item.headline"
                class="min-height-3 html-text-ellipsis-2"
                v-html="item.headline"
              />
              <span
                v-if="item.description"
                class="min-height-3 html-text-ellipsis-2 content-container"
                v-html="item.description"
              />
              <div
                v-if="item.headline == null && item.description == null"
                class="min-height-3"
              />
              <div
                v-if="item.headline == null || item.description == null"
                class="min-height-3"
              />
              <b-card-text class="w-100 mt-2 text-muted">
                <span class="icon-container">
                  <feather-icon
                    size="20"
                    :class="
                      item.likedByMember ? 'profile-likes' : 'profile-icon'
                    "
                    icon="HeartIcon"
                    class="mr-25"
                    @click.stop.prevent="toggleLike(item)"
                  />
                  {{ item.totalLikes }}
                </span>
              </b-card-text>
            </b-link>
          </b-card>

          <!-- Buttons prev and next -->
          <button
            class="link-previous btn btn-icon btn-primary btn-previous"
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>
      <b-row v-else-if="!canList" class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="SuccessPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("no-permisions.message") }}
          </p>
        </b-col>
      </b-row>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col v-if="SuccessPlaceholder" cols="12">
          <img :src="SuccessPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("successstories.placeholder") }}
          </p>
        </b-col>
      </b-row>
    </b-card-body>
    
    <description-addon v-model="isAppInfoModalVisible" :app-id="1" />
  </list-container-widget>
</template>

<script>
import SuccessPlaceholder from "@/assets/images/placeholders/light/success_stories.svg";
import { getImageResource } from "@/@core/utils/image-utils";
import Ripple from "vue-ripple-directive";
import { checkPermissions } from "@/@core/utils/roles-utils";
import DescriptionAddon from "@/@core/widgets/DescriptionAddon.vue";
import Placeholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
// import { quillEditor } from "vue-quill-editor";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
// import { translateTranslationTable } from "@/@core/libs/i18n/utils";
// import vSelect from "vue-select";
import FileTypes from "@/@core/constants/FileTypes";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
// import FileUpload from "@core/components/files/FileUpload.vue";

export default {
  name: "SuccessListWidget",
  components: {
    DescriptionAddon,
    ListContainerWidget,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    CreateNewButton: () => import ("@/@core/components/widget-actions/CreateNewButton.vue"  /* webpackChunkName: "CreateNewButton" */),
  },
  directives: {
    Ripple,
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      SuccessPlaceholder,
      loadingNext: false,
      successstories: [],
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      projectInput: {},
      isAppInfoModalVisible: false,
      imageProject: null,
      imageSrc: null,
      searchInput: "",
      results: [],

      // Filters data
      // areFiltersVisible: false,
      // IsFilter: false,
      // nextFilter: null,
      // selectedClassifiersForFilter: [],
      // finalSelectClassifier: [],
      // customFieldToSend: {},
      // selectedCustomFieldsForFilter: null,
      // showfilter: false,
    };
  },
  computed: {
    FileTypes() {
      return FileTypes;
    },
    // Filtros computed
    classifiers() {
      if (this.$store.getters.classifiers.successstories) {
        return this.$store.getters.classifiers.successstories.unpaginated;
      }
      return [];
    },
    // sortedCommunityClassifiers() {
    //   const newObject = {};
    //   for (const item of this.classifiers) {
    //     if (
    //       !newObject[
    //         translateTranslationTable(
    //           this.$store.state.locale.currentLocale,
    //           item.typeName
    //         )
    //       ]
    //     ) {
    //       newObject[
    //         translateTranslationTable(
    //           this.$store.state.locale.currentLocale,
    //           item.typeName
    //         )
    //       ] = [item];
    //     } else {
    //       newObject[
    //         translateTranslationTable(
    //           this.$store.state.locale.currentLocale,
    //           item.typeName
    //         )
    //       ].push(item);
    //     }
    //   }
    //   const keys = Object.keys(newObject);
    //   const finalKeys = [];
    //   for (const row of keys) {
    //     finalKeys.push({
    //       key: row,
    //       name: row,
    //       type: 1,
    //     });
    //   }
    //   return [newObject, finalKeys];
    // },
    // finalOptions() {
    //   return [].concat(
    //     this.sortedCommunityClassifiers[1],
    //     this.customFieldsOptions
    //   );
    // },
    // Final filtros computed

    // Load data from store
    itemsData() {
      return this.$store.getters.successstories;
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData.unpaginated;
      return itemsToIterate;
    },
    Placeholder() {
      return Placeholder;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "success-story",
        this.loggedMemberRoles,
        this.collective
      );
    },
    canList() {
      return checkPermissions(
        "index",
        "success-story",
        this.loggedMemberRoles,
        this.$store.getters.currentCollective
      );
    },
  },
  watch: {
    imageProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    // await this.fetchClassifiers();
    this.isLoading = false;
  },
  methods: {
    canView(item) {
      return (
        this.loggedUser.key === item.ownedByUser?.key ||
        this.isStaff ||
        checkPermissions(
          "index",
          "success-story",
          this.loggedMemberRoles,
          this.$store.getters.currentCollective
        )
      );
    },
    // canFilter() {
    //   if (this.classifiers.length > 0) {
    //     this.showfilter = true;
    //   } else {
    //     this.showfilter = false;
    //   }
    // },
    // // Filtros methods
    // toggleFilters() {
    //   this.IsFilter = true;
    //   this.areFiltersVisible = !this.areFiltersVisible;
    //   if (!this.areFiltersVisible) {
    //     this.customFieldToSend = null;
    //     this.finalSelectClassifier = [];
    //     (this.total = 0),
    //       (this.filtersIndex = 0),
    //       (this.filters = []),
    //       (this.parentClassifiers = []),
    //       (this.selectedClassifiersForFilter = []);
    //     this.customFieldsInputs = [];
    //     this.index = 0;
    //     this.fetchData(true);
    //   }
    // },
    handleChanger() {
      this.fetchData(true);
    },
    // async setSeletedFinalFilter(selected) {
    //   if (selected.type === 2) {
    //     this.customFieldToSend[selected.key] = selected.text;
    //   } else if (selected) {
    //     this.finalSelectClassifier = selected.key;
    //   } else {
    //     this.finalSelectClassifier = [];
    //   }
    //   this.handleChanger();
    // },
    // async fetchClassifiers() {
    //   this.isLoading = true;
    //   await this.$store.dispatch("getItems", {
    //     itemType: "classifiers",
    //     storedKey: "successstories",
    //     page: 1,
    //     requestConfig: {
    //       morphType: "successstories",
    //       count: 1000,
    //     },
    //   });
    //   this.canFilter();
    // },
    // async setSeletedFilter(selected) {
    //   this.CustomFieldToSend = null;
    //   this.finalSelectClassifier = null;
    //   this.selectedCustomFieldsForFilter = null;
    //   this.nextFilter = null;
    //   if (selected.length === 0) {
    //     this.finalSelectClassifier = null;
    //   } else {
    //     let translated = [];
    //     if (selected.type === 1) {
    //       for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
    //         translated.push({
    //           name: translateTranslationTable(
    //             this.$store.state.locale.currentLocale,
    //             row.name
    //           ),
    //           key: row.key,
    //           type: 1,
    //         });
    //       }
    //     } else if (selected.type === 2) {
    //       this.customFieldToSend[selected.key] = "";
    //       translated = {
    //         type: 2,
    //         key: selected.key,
    //       };
    //     }
    //     this.nextFilter = translated;
    //   }
    // },
    // Final filtros methods
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async fetchData(force = false) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "successstories",
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          count: 15,
          orderByDate: -1,
          // classifiers: this.finalSelectClassifier,
          // customFields:
          //   this.customFieldToSend != null &&
          //   Object.keys(this.customFieldToSend).length > 0
          //     ? this.customFieldToSend
          //     : null,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 10 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length <= total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    async handleCreate() {
      if (!this.projectInput.name) {
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "successstories",
            requestConfig: {
              name: this.projectInput.name,
              description: this.projectInput.description,
            },
          },
          file: this.imageProject,
        });
        this.fetchData();
        this.projectInput = {};
        this.imageProject = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    timestampToTime(timestamp) {
      return new Date(timestamp).toLocaleTimeString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    async toggleLike(item) {
      await this.$store.dispatch("toggleLike", {
        itemType: "successstories",
        morphType: "successstories",
        key: item.key,
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      );
    },
  },
};
</script>
<style scoped>
.icon-container {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}
.card-container {
  height: 450px;
}
.content-container > img {
  width: 100%;
}
.card-standard {
  vertical-align: top;
}
</style>
